import { CreateOutlined, DeleteOutlineOutlined } from "@mui/icons-material";
import { Box, useMediaQuery } from "@mui/material";
import {
	GridActionsCellItem,
	GridColDef,
	GridRowParams,
} from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmptyListAnimation } from "../../animations/EmptyListAnimation";
import useFaq from "../../hooks/useFaq";
import { savePage, storeFaqDetails } from "../../redux/features/faq/faqSlice";
import { saveModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { theme } from "../../theme";
import { DELETE_FAQ_MODAL, FAQ_MODAL } from "../../utils/constants";
import { DataGridTemplate } from "../datagrid";
import DeleteFaqModal from "../modals/DeleteFaqModal";
import FaqModal from "../modals/FaqModal";
import { TableTemplate } from "../table/TableTemplate";

const FaqsList = () => {
	const { getFaqList } = useFaq();
	const dispatch = useDispatch();
	const isXs = useMediaQuery(theme.breakpoints.only("xs"));
	const isSm = useMediaQuery(theme.breakpoints.up("sm"));
	const { faqs, pagination, isFaqLoading, page } = useSelector(
		(state: RootState) => state.faq
	);

	const handlePageChange = (event: any, newPage: any) => {
		dispatch(savePage);
	};

	const handleEditClick = (params: GridRowParams) => {
		dispatch(storeFaqDetails(params.row));
		dispatch(saveModalName(FAQ_MODAL));
	};
	const handleDelete = (params: GridRowParams) => {
		dispatch(storeFaqDetails(params.row));
		dispatch(saveModalName(DELETE_FAQ_MODAL));
	};

	const columns: GridColDef[] = [
		{
			field: "id",
			headerName: "ID",
			sortable: false,
			width: 100,
			// align: "center",
			// flex: isSm ? 0.3 : undefined,
			// width: isXs ? 180 : undefined,
		},
		{
			field: "question",
			headerName: "Question",
			sortable: false,

			flex: isSm ? 1 : undefined,
			width: isXs ? 200 : undefined,
		},
		{
			field: "score",
			headerName: "Score",
			sortable: false,
			align: "center",
			headerAlign: "center",
			flex: isSm ? 0.4 : undefined,
			width: isXs ? 100 : undefined,
		},
		{
			field: "order",
			headerName: "Order",
			align: "center",
			headerAlign: "center",
			sortable: false,
			flex: isSm ? 0.4 : undefined,
			width: isXs ? 100 : undefined,
		},

		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			// width: 80,
			getActions: (params) => [
				<GridActionsCellItem
					icon={<CreateOutlined fontSize="small" />}
					label="Edit"
					onClick={() => handleEditClick(params)}
				/>,
				<GridActionsCellItem
					icon={<DeleteOutlineOutlined fontSize="small" />}
					label="Delete"
					onClick={() => handleDelete(params)}
				/>,
			],
		},
	];
	// let searchedListers: any[] = [];
	// const handleSearch = async (value: string) => {
	//   setSearchValue(value);
	// };
	useEffect(() => {
		getFaqList(page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);
	return (
		<Box pt={3}>
			<TableTemplate
				// search={
				//   <SearchInput
				//     searchCallback={handleSearch}
				//     searchLabel={`Search Team Members...`}
				//   />
				// }
				list={faqs}
				dataGrid={
					<DataGridTemplate
						paginationData={pagination}
						list={faqs}
						columns={columns}
						page={page}
						handlePageChange={handlePageChange}
					/>
				}
				buttonGroups={null}
				emptyView={<EmptyListAnimation title="No faq found" />}
				loading={isFaqLoading}
			/>
			<DeleteFaqModal />
			<FaqModal />
		</Box>
	);
};
export default FaqsList;

// import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import React from "react";
// import { useDispatch } from "react-redux";
// import PrimaryButton from "../components/buttons/PrimaryButton";
import ContentWrapper from "../components/common/ContentWrapper";
// import HeaderWrapper from "../components/common/HeaderWrapper";
// import ContentModal from "../components/modals/ContentModal";
// import useTag from "../hooks/useTag";
// import { saveModalName } from "../redux/features/modal/modalSlice";
// import { CONTENT_MODAL } from "../utils/constants";
import PreviewsList from "../components/previews/PreviewList";
import PreviewModal from "../components/modals/PreviewModal";
// import { PREVIEW_MODAL } from "../utils/constants";
// import { clearPreviewDetils } from "../redux/features/previews/previewSlice";

const Preview: React.FC = () => {
	// const dispatch = useDispatch();
	// const handleUploadModal = () => {
	// 	dispatch(clearPreviewDetils());
	// 	dispatch(saveModalName(PREVIEW_MODAL));
	// };
	return (
		<ContentWrapper>
			<Box
				sx={{
					padding: "32px",
				}}
			>
				{/* <HeaderWrapper>
					<Heading variant="h6">Previews</Heading>
					<PrimaryButton
						text="Add Preview"
						startIcon={<AddIcon />}
						onClick={handleUploadModal}
					/>
				</HeaderWrapper> */}
				<PreviewsList />
				<PreviewModal />
			</Box>
		</ContentWrapper>
	);
};

export default Preview;

// const Heading = styled(Typography)(({ theme }) => ({
// 	[theme.breakpoints.only("xs")]: {
// 		fontSize: "26px",
// 		marginBottom: "16px",
// 	},
// }));

import { Box, Grid, styled } from "@mui/material";
import { Outlet } from "react-router-dom";
import LeftNavigation from "../components/navigations/LeftNavigation";
// import TopNavigation from "../components/navigations/TopNavigation";

const MainPageTemplate = () => {
	// const TemplateGrid = styled(Grid)(({ theme }) => ({
	// 	overflow: "auto",
	// 	background: "#fff",
	// 	flex: 1,
	// }));
	const NavigationContainer = styled(Grid)(({ theme }) => ({
		display: "flex",
		width: "100%",
		// flex: "0 0 180px",
		background: "#F0F4F7",
		borderRight: "1px solid #e6e6e6",
		// transition: "0.5s all ease-in-out",
		// [theme.breakpoints.down("md")]: {
		// 	flex: "0 0 50px",
		// },
	}));

	return (
		//
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				// justifyContent="center",
				// alignItems="center",
				width: "100%",
				// flexDirection: "column",

				// height: "100vh",
				// overflow: "hidden",
			}}
		>
			<NavigationContainer>
				<LeftNavigation />
			</NavigationContainer>
			{/* <Grid display="flex" height="100vh">
				<TemplateGrid
					xs={12}
					display="flex"
					flexDirection="row"
					// flexDirection="column"
					// height="100%"
				> */}
			{/* <TopNavigation /> */}
			<Box
				// flex={1}
				// p={4}
				overflow="auto"
				display="flex"
				// justifyContent="flex-start"
				// alignItems="flex-start"
				justifyContent="center"
				alignItems="center"
				boxSizing="border-box"
				// sx={{ background: "#f8f8f8" }}
				// mt={2}
				width="100%"
			>
				<Outlet />
			</Box>
			{/* </TemplateGrid>
			</Grid> */}
		</Box>
	);
};

export default MainPageTemplate;

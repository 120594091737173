import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "../contexts/Authcontext";
import { DatasetCreateRequest } from "../openapi";
import { DatasetUpdateReq } from "../openapi/models/dataset-update-req";
import {
  clearDatasetDetils,
  editDataset,
  saveLoading,
  storeDatasetDetails,
  storeDatasets,
  storePaginationDetails,
} from "../redux/features/datasets/datasetsSlice";
import { clearModalName } from "../redux/features/modal/modalSlice";
import { RootState } from "../redux/store";
import { getOffset } from "../utils";
import { getDatasetService } from "../utils/apiHelpers";
import { PAGE_LIMIT } from "../utils/constants";
// import Keywords from "../containers/Keywords";

const useDataset = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { accessToken } = useAuthContext();
  const { dataset } = useSelector((state: RootState) => state.dataset);

  const getDatasetList = async (page: number, category?: string, link?: string) => {
    let offset = getOffset(page);
    if (accessToken) {
      dispatch(saveLoading(true));
      try {
        // if (category === "all") {
        //   category = undefined;
        // }
        const service = await getDatasetService(accessToken);
        const response = await service.getAdminList(link, category, PAGE_LIMIT, offset);
        if (response.status === 200 || response.status === 201) {
          dispatch(storeDatasets(response.data.datasets));
          dispatch(storePaginationDetails(response.data));
          // dispatch(saveLoading(false));
        } else {
          console.log("error", response.data);
          // dispatch(saveLoading(false));
        }
      } catch (error: any) {
        // dispatch(saveLoading(false));
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        // if (error.response.status === 500) {
        //   navigate("*");
        // }
      } finally {
        dispatch(saveLoading(false));
      }
    }
  };
  const getDatasetById = async (id: number) => {
    if (accessToken) {
      dispatch(saveLoading(true));
      try {
        const service = await getDatasetService(accessToken);
        const response = await service.getDataset(id)
        if (response.status === 200 || response.status === 201) {
          dispatch(storeDatasetDetails(response.data));
          // dispatch(saveLoading(false));
          return response.data;
        }
      } catch (error: any) {
        // dispatch(saveLoading(false));
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        // if (error.response.status === 500) {
        //   navigate("*");
        // }
      } finally {
        dispatch(saveLoading(false));
      }
    }
  };
  const createContent = async (value: any) => {
    if (accessToken && value) {
      dispatch(saveLoading(true));
      const createRequest: DatasetCreateRequest = {
        bodyText: value.bodyText,
        link: value.link,
        tags: value.tags,
        title: value.title,
        summary: value.summary,
        keywords: value.keywords,
        resourceQuestions: value.resourceQuestions,
        category: value.category,
      };
      try {
        const service = await getDatasetService(accessToken);
        const response = await service.createDataset(createRequest);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Content created successfully", {
            variant: "success",
          });
          //dispatch(saveLoading(false));
          //dispatch(createTags(true));
          getDatasetList(1);
          return response.data;
        } else {
          console.log("error", response.data);
          // dispatch(saveLoading(false));
        }
      } catch (error: any) {
        // dispatch(saveLoading(false));
        console.log("error", error);
        enqueueSnackbar(error?.response?.data?.message.toString() ?? error.toString(), {
          variant: "error",
        });
      } finally {
        dispatch(saveLoading(false));
      }
    }
  };
  const updateDataset = async (values: any) => {
    if (accessToken && dataset) {
      dispatch(saveLoading(true));
      const updateRequest: DatasetUpdateReq = {
        id: dataset.id,
        bodyText: values.bodyText,
        tags: values.tags,
        link: values.link,
        title: values.title,
        summary: values.summary,
        keywords: values.keywords,
        resourceQuestions: values.resourceQuestions,
        category: values.category,
      };
      try {
        const service = await getDatasetService(accessToken);
        const response = await service.updateTag(updateRequest);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Content updated successfully", {
            variant: "success",
          });
          // dispatch(saveLoading(false));
          dispatch(editDataset(updateRequest));
          getDatasetList(1);
          return response.data;
        } else {
          console.log("error", response.data);
          // dispatch(saveLoading(false));
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        // dispatch(saveLoading(false));
        console.log("error", error);
      } finally {
        dispatch(saveLoading(false));
      }
    }
  };
  const deleteDataset = async () => {
    if (accessToken && dataset) {
      dispatch(saveLoading(true));
      try {
        const productService = await getDatasetService(accessToken);
        const response = await productService.deleteDataset(dataset.id);
        if (response.status === 200) {
          enqueueSnackbar("Content deleted successfully", {
            variant: "success",
          });
          getDatasetList(1);
          // dispatch(saveLoading(false));
          dispatch(clearModalName());
          dispatch(clearDatasetDetils());
          return response;
        } else {
          console.log("error", response.data);
          // dispatch(saveLoading(false));
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        // dispatch(saveLoading(false));
        console.log("error", error);
      } finally {
        dispatch(saveLoading(false));
      }
    }
  };
  const vectorizeDataset = async (id: number, category?: string,) => {
    if (accessToken) {
      dispatch(saveLoading(true));
      try {
        const service = await getDatasetService(accessToken);
        const response = await service.vectorize(id);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Vectorization started successfully", {
            variant: "success",
          });
          getDatasetList(1, category);
          // return response.data;
        } else {
          console.log("error", response.data);
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
      } finally {
        dispatch(saveLoading(false));
      }
    }
  }
  const generatePreview = async (id: number, category?: string) => {
    if (accessToken) {
      dispatch(saveLoading(true));
      try {
        const service = await getDatasetService(accessToken);
        const response = await service.generatePreview(id);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Preview generated successfully", {
            variant: "success",
          });
          getDatasetList(1, category);
        } else {
          console.log("error", response.data);
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
      } finally {
        dispatch(saveLoading(false));
      }
    }
  }
  return {
    createContent,
    getDatasetList,
    updateDataset,
    deleteDataset,
    getDatasetById,
    vectorizeDataset,
    generatePreview,
  };
};
export default useDataset;

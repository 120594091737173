import { CognitoUser } from "@aws-amplify/auth";
import { AlertColor } from "@mui/material";
import { Auth } from "aws-amplify";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signUp } from "../auth";
import { IResetPasswordStep1Form } from "../interfaces/IResetPasswordStep1Form";
import { IResetPasswordStep2Form } from "../interfaces/IResetPasswordStep2Form";
import { ISignInFormValues } from "../interfaces/ISignInFormValues";
import { ISignUpFormValues } from "../interfaces/ISignUpFormValues";
import { IVerifyEmailValues } from "../interfaces/IVerifyEmailValues";
import { UserType } from "../openapi";
import { UserCreateRequest } from "../openapi/models/user-create-request";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  saveInLocalStorage,
} from "../utils";
import { getCreateUserService } from "../utils/apiHelpers";
import { ADMIN_INFO, LS_EMAIL } from "../utils/constants";

export interface IError {
  message: string;
  severity: AlertColor;
  redirectUrl?: string;
  pageLabel?: string;
}

interface CognitoToken extends JwtPayload {
  sub: string;
  "cognito:groups": string[];
}

const useAuth = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<IError | null>(null);
  const [resendCountdownStart, setResendCountdownStart] =
    useState<boolean>(false);

  const handleLogin = async (values: ISignInFormValues) => {
    const { email, password } = values;
    setLoading(true);
    setError(null);
    try {
      const user = await Auth.signIn(email, password);
      const groups = getUserGroups(user);
      if (groups.includes("Admin")) {
        saveInLocalStorage(
          "accessToken",
          user.signInUserSession.accessToken.jwtToken
        );
        saveInLocalStorage("authUser", JSON.stringify(user.attributes));
        window.dispatchEvent(new Event("storage"));
        return user;
      } else {
        setError({
          message: "You can't use this email in admin panel",
          severity: "error",
        });
      }
    } catch (error: any) {
      if (error.name === "UserNotConfirmedException") {
        setError({
          message:
            "Your account is not confirmed yet. We have sent a verification code in your email.",
          severity: "error",
          redirectUrl: "/verify-email",
          pageLabel: "verify-email",
        });
        saveInLocalStorage(LS_EMAIL, values.email);
      } else if (error.name === "NotAuthorizedException") {
        setError({
          message: "Incorrect email or password",
          severity: "error",
        });
      } else {
        setError({
          message: "Something went wrong!",
          severity: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const getUserGroups = (user: any) => {
    const decode: CognitoToken = jwt_decode(
      user.signInUserSession.accessToken.jwtToken
    );
    console.log("decode", decode);
    const groups = decode["cognito:groups"];
    return groups;
  };

  const handleConfirmEmail = async (values: IVerifyEmailValues) => {
    const { email, code } = values;
    setLoading(true);
    setError(null);
    try {
      await Auth.confirmSignUp(email, code);
      enqueueSnackbar("Email varified successfully", { variant: "success" });
      removeFromLocalStorage(LS_EMAIL);
      const response = await createAdminUser();
      if (response) return true;
    } catch (error: any) {
      setError({
        message: error?.message,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const createAdminUser = async () => {
    let adminInfo = getFromLocalStorage(ADMIN_INFO);
    if (adminInfo) {
      let userInfo = JSON.parse(adminInfo);
      let createRequest: UserCreateRequest = {
        userType: UserType.Admin,
        cognitoUserId: userInfo.cognitoUserId,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        stripeCustomerId: "none",
        subscriptionType: "Free",
      };
      const service = await getCreateUserService();
      const response = await service.createUser(createRequest);
      if (response) {
        const adminInfo: ISignInFormValues = {
          email: userInfo.email,
          password: userInfo.password,
        };
        handleLogin(adminInfo);
      } else {
        setError({
          message: "Something went wrong!",
          severity: "error",
        });
      }
      return true;
    }
  };
  const handleResendCode = async () => {
    const email = getFromLocalStorage(LS_EMAIL);
    setError(null);
    if (email) {
      try {
        await Auth.resendSignUp(email);
        setResendCountdownStart(true);
        setError({
          message: "We have sent another verification code in your email.",
          severity: "info",
        });
      } catch (error: any) {
        setError({
          message: error?.message,
          severity: "error",
        });
      }
    } else {
      setError({
        message: "Something went wrong",
        severity: "error",
      });
    }
  };

  const getCode = async (values: IResetPasswordStep1Form) => {
    setError(null);
    try {
      await Auth.forgotPassword(values.email);
      localStorage.setItem("email", values.email);
      window.dispatchEvent(new Event("storage"));
    } catch (error: any) {
      setError({ message: error?.message, severity: "error" });
    }
  };

  const getResetPasswordCodeByEmail = async (
    values: IResetPasswordStep1Form
  ) => {
    setLoading(true);
    setError(null);
    try {
      getCode(values);
    } catch (error: any) {
      setError({ message: error?.message, severity: "error" });
    } finally {
      setLoading(false);
    }
  };

  const resetPasswordWithCode = async (values: IResetPasswordStep2Form) => {
    const { email, code, password } = values;
    setLoading(true);
    setError(null);
    try {
      const response = await Auth.forgotPasswordSubmit(email, code, password);
      if (response) {
        enqueueSnackbar("Password changed successfully", {
          variant: "success",
        });
        removeFromLocalStorage(LS_EMAIL);
      }
      return true;
    } catch (error: any) {
      setError({ message: error?.message, severity: "error" });
    } finally {
      setLoading(false);
    }
  };
  const handleCreateUser = async (values: ISignUpFormValues) => {
    setLoading(true);
    setError(null);
    try {
      // const service = getCustomerService();
      // const response = await service.getUserTypeByEmail(values.email);
      // if (response.data) {
      //   if (response.data.type === UserType.Customer) {
      //     setError({
      //       message:
      //         "Provided email is associated with customer account. You can not use customer account in admin panel.",
      //       severity: "error",
      //     });
      //   } else {
      //     setError({
      //       message: "This email already in use.",
      //       severity: "error",
      //     });
      //   }
      // }

      const user: CognitoUser = await signUp(values);
      saveInLocalStorage(LS_EMAIL, user.getUsername());
      const adminUser = getFromLocalStorage(ADMIN_INFO);
      if (adminUser) {
        const adminInfo = JSON.parse(adminUser);
        if (adminInfo.userConfirmed) {
          const response = await createAdminUser();
          if (response) return true;
        } else {
          navigate("/verify-email");
        }
      }
    } catch (error: any) {
      setError({ message: error?.message, severity: "error" });
    } finally {
      setLoading(false);
    }
  };

  return {
    error,
    setError,
    loading,
    handleLogin,
    handleConfirmEmail,
    resendCountdownStart,
    handleResendCode,
    setResendCountdownStart,
    getResetPasswordCodeByEmail,
    resetPasswordWithCode,
    handleCreateUser,
  };
};
export default useAuth;

/* tslint:disable */
/* eslint-disable */
/**
 * Main Service API
 * Main Service API
 *
 * The version of the OpenAPI document: 2.11.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PreviewsList } from '../models';
// @ts-ignore
import { PreviewsListResponse } from '../models';
// @ts-ignore
import { PreviewsRequest } from '../models';
// @ts-ignore
import { PreviewsResponse } from '../models';
// @ts-ignore
import { PreviewsUpdateRequest } from '../models';
/**
 * PreviewsApi - axios parameter creator
 * @export
 */
export const PreviewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {Array<PreviewsRequest>} previewsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatchPreviews: async (previewsRequest: Array<PreviewsRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'previewsRequest' is not null or undefined
            assertParamExists('createBatchPreviews', 'previewsRequest', previewsRequest)
            const localVarPath = `/main/previews/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(previewsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {PreviewsRequest} previewsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreviews: async (previewsRequest: PreviewsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'previewsRequest' is not null or undefined
            assertParamExists('createPreviews', 'previewsRequest', previewsRequest)
            const localVarPath = `/main/previews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(previewsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePreviews: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePreviews', 'id', id)
            const localVarPath = `/main/previews/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewsByUrls: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getPreviewsByUrls', 'requestBody', requestBody)
            const localVarPath = `/main/previews/getPreviewsByUrls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewsLikeByString: async (limit?: string, offset?: string, url?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/previews/like`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewssForAdmin: async (limit?: string, offset?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/previews/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewssForCustomer: async (limit?: string, offset?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/previews/getPreviewss`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {PreviewsUpdateRequest} previewsUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreviews: async (previewsUpdateRequest: PreviewsUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'previewsUpdateRequest' is not null or undefined
            assertParamExists('updatePreviews', 'previewsUpdateRequest', previewsUpdateRequest)
            const localVarPath = `/main/previews/{id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(previewsUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PreviewsApi - functional programming interface
 * @export
 */
export const PreviewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PreviewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {Array<PreviewsRequest>} previewsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBatchPreviews(previewsRequest: Array<PreviewsRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PreviewsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBatchPreviews(previewsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {PreviewsRequest} previewsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPreviews(previewsRequest: PreviewsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPreviews(previewsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePreviews(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePreviews(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreviewsByUrls(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviewsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreviewsByUrls(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreviewsLikeByString(limit?: string, offset?: string, url?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviewsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreviewsLikeByString(limit, offset, url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreviewssForAdmin(limit?: string, offset?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviewsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreviewssForAdmin(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreviewssForCustomer(limit?: string, offset?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviewsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreviewssForCustomer(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {PreviewsUpdateRequest} previewsUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePreviews(previewsUpdateRequest: PreviewsUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePreviews(previewsUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PreviewsApi - factory interface
 * @export
 */
export const PreviewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PreviewsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {Array<PreviewsRequest>} previewsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBatchPreviews(previewsRequest: Array<PreviewsRequest>, options?: any): AxiosPromise<Array<PreviewsResponse>> {
            return localVarFp.createBatchPreviews(previewsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {PreviewsRequest} previewsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreviews(previewsRequest: PreviewsRequest, options?: any): AxiosPromise<PreviewsResponse> {
            return localVarFp.createPreviews(previewsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePreviews(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.deletePreviews(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewsByUrls(requestBody: Array<string>, options?: any): AxiosPromise<PreviewsListResponse> {
            return localVarFp.getPreviewsByUrls(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewsLikeByString(limit?: string, offset?: string, url?: string, options?: any): AxiosPromise<PreviewsList> {
            return localVarFp.getPreviewsLikeByString(limit, offset, url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewssForAdmin(limit?: string, offset?: string, options?: any): AxiosPromise<PreviewsList> {
            return localVarFp.getPreviewssForAdmin(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewssForCustomer(limit?: string, offset?: string, options?: any): AxiosPromise<PreviewsList> {
            return localVarFp.getPreviewssForCustomer(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {PreviewsUpdateRequest} previewsUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreviews(previewsUpdateRequest: PreviewsUpdateRequest, options?: any): AxiosPromise<PreviewsResponse> {
            return localVarFp.updatePreviews(previewsUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PreviewsApi - object-oriented interface
 * @export
 * @class PreviewsApi
 * @extends {BaseAPI}
 */
export class PreviewsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {Array<PreviewsRequest>} previewsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewsApi
     */
    public createBatchPreviews(previewsRequest: Array<PreviewsRequest>, options?: AxiosRequestConfig) {
        return PreviewsApiFp(this.configuration).createBatchPreviews(previewsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {PreviewsRequest} previewsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewsApi
     */
    public createPreviews(previewsRequest: PreviewsRequest, options?: AxiosRequestConfig) {
        return PreviewsApiFp(this.configuration).createPreviews(previewsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewsApi
     */
    public deletePreviews(id: number, options?: AxiosRequestConfig) {
        return PreviewsApiFp(this.configuration).deletePreviews(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewsApi
     */
    public getPreviewsByUrls(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return PreviewsApiFp(this.configuration).getPreviewsByUrls(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} [limit] 
     * @param {string} [offset] 
     * @param {string} [url] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewsApi
     */
    public getPreviewsLikeByString(limit?: string, offset?: string, url?: string, options?: AxiosRequestConfig) {
        return PreviewsApiFp(this.configuration).getPreviewsLikeByString(limit, offset, url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} [limit] 
     * @param {string} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewsApi
     */
    public getPreviewssForAdmin(limit?: string, offset?: string, options?: AxiosRequestConfig) {
        return PreviewsApiFp(this.configuration).getPreviewssForAdmin(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} [limit] 
     * @param {string} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewsApi
     */
    public getPreviewssForCustomer(limit?: string, offset?: string, options?: AxiosRequestConfig) {
        return PreviewsApiFp(this.configuration).getPreviewssForCustomer(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {PreviewsUpdateRequest} previewsUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewsApi
     */
    public updatePreviews(previewsUpdateRequest: PreviewsUpdateRequest, options?: AxiosRequestConfig) {
        return PreviewsApiFp(this.configuration).updatePreviews(previewsUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

import { CreateOutlined, DeleteOutlineOutlined } from "@mui/icons-material";
import {
	Box,
	Chip,
	MenuItem,
	Select,
	SelectChangeEvent,
	styled,
	useMediaQuery,
} from "@mui/material";
import {
	GridActionsCellItem,
	GridColDef,
	GridRenderCellParams,
	GridRowParams,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmptyListAnimation } from "../../animations/EmptyListAnimation";
import useDataset from "../../hooks/useDataset";
// import { TagResponse } from "../../openapi";
import { storeDatasetDetails } from "../../redux/features/datasets/datasetsSlice";
import { saveModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { theme } from "../../theme";
import { CONTENT_MODAL, DELETE_CONTENT_MODAL } from "../../utils/constants";
import DeleteContentModal from "../modals/DeleteContentModal";
import { TableTemplate } from "../table/TableTemplate";
import { TeamDataGrid } from "./ContentDataGrid";
import NewSearchBar from "../common/NewSearchBar";
import ReplayIcon from "@mui/icons-material/Replay";
// import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import useTag from "../../hooks/useTag";
import PreviewIcon from "@mui/icons-material/Preview";

const SearchFormWrapper = styled(Box)(() => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "8px",
}));
const ContentsList = () => {
	const {
		getDatasetList,
		getDatasetById,
		vectorizeDataset,
		generatePreview,
	} = useDataset();
	const dispatch = useDispatch();
	const { getTagList } = useTag();
	const { categories } = useSelector((state: RootState) => state.tag);
	const isXs = useMediaQuery(theme.breakpoints.only("xs"));
	const isSm = useMediaQuery(theme.breakpoints.up("sm"));
	const [page, setPage] = useState(1);
	const { datasets, pagination, isLoading } = useSelector(
		(state: RootState) => state.dataset
	);
	const [searchContent, setSearchContent] = useState("");
	const [searchType, setSearchType] = useState("all");
	const onSearchTypeChange = (event: SelectChangeEvent<string>) => {
		// const value = event.target.value === "all" ? "" : event.target.value;
		setSearchType(event.target.value as string);
		if (searchContent) {
			getDatasetList(page, event.target.value as string, searchContent);
		} else {
			getDatasetList(page, event.target.value as string);
		}
	};
	const handleChange = (value: string) => {
		// console.log("handleChange for: ", value);
		const inputText = value;
		setSearchContent(inputText ?? "");
	};
	const handleSubmit = () => {
		// console.log(searchContent);
		if (searchContent) getDatasetList(page, searchType, searchContent);
	};
	const onCancelResearch = () => {
		// console.log(searchContent);
		setSearchContent("");
		getDatasetList(page, searchType);
	};
	const handlePageChange = (event: any, newPage: any) => {
		setPage(newPage);
	};
	const handleEditClick = (params: GridRowParams) => {
		getDatasetById(params.row.id).then(() => {
			dispatch(saveModalName(CONTENT_MODAL));
		});
	};
	const handleDelete = (params: GridRowParams | GridRenderCellParams) => {
		console.log("Delete clicked", params.row);
		dispatch(storeDatasetDetails(params.row));
		dispatch(saveModalName(DELETE_CONTENT_MODAL));
	};
	const handleRetry = (params: GridRowParams) => {
		console.log("Retry clicked", params.row);
		vectorizeDataset(params.row.id, searchType);
	};

	const generatePreviewRetry = (params: GridRowParams) => {
		console.log("Generate Preview clicked", params.row);
		generatePreview(params.row.id, searchType);
	};
	const columns: GridColDef[] = [
		{
			field: "delete",
			headerName: "Dlt",
			// flex: isSm ? 0.1 : undefined,
			// width: isXs ? 40 : undefined,
			width: 20,
			hideable: false,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<GridActionsCellItem
						icon={<DeleteOutlineOutlined fontSize="small" />}
						label="Delete"
						onClick={() => handleDelete(params)}
						disabled={params.row.status === "Processing"}
					/>
				);
			},
		},
		{
			field: "id",
			headerName: "ID",
			sortable: false,
			// flex: isSm ? 0.1 : undefined,
			// width: isXs ? 50 : undefined,
			width: 10,
			hideable: false,
			// maxWidth: 50,
			// minWidth: 10,
		},
		{
			field: "link",
			headerName: "Link",
			sortable: false,
			// flex: isSm ? 1 : undefined,
			// width: isXs ? 450 : undefined,
			width: 450,
			hideable: false,
		},
		{
			field: "title",
			headerName: "Title",
			sortable: false,
			flex: isSm ? 1 : undefined,
			width: isXs ? 250 : undefined,
			// width: 250,
			hideable: false,
		},
		{
			field: "summary",
			headerName: "Summary",
			sortable: false,
			flex: isSm ? 0.3 : undefined,
			width: isXs ? 100 : undefined,
		},
		{
			field: "bodyText",
			headerName: "Content",
			sortable: false,
			flex: isSm ? 0.3 : undefined,
			width: isXs ? 100 : undefined,
		},
		{
			field: "category",
			headerName: "Category",
			sortable: false,
			flex: isSm ? 0.3 : undefined,
			width: isXs ? 100 : undefined,
			hideable: false,
			renderCell: (params: GridRenderCellParams) => {
				let keywordLeft = null;
				if (params.row?.category?.length > 1) {
					keywordLeft = params.row.category.length - 1;
				}
				return (
					<Box maxWidth={"150px"}>
						{params.row?.category?.length > 1 ? (
							<>
								<Chip
									variant="filled"
									label={params.row.category[0]}
									size="small"
									sx={{
										borderRadius: "4px",
										fontSize: "14px",
										marginRight: "10px",
									}}
								/>
								+ {keywordLeft}
							</>
						) : params.row?.category?.length <= 1 ||
						  params.row?.category?.length !== 0 ? (
							params.row?.category?.map(
								(data: string, index: number) => {
									return (
										<Chip
											key={index}
											variant="filled"
											label={data}
											size="small"
											sx={{
												borderRadius: "4px",
												fontSize: "14px",
												marginRight: "10px",
											}}
										/>
									);
								}
							)
						) : null}
					</Box>
				);
			},
		},
		{
			field: "status",
			headerName: "Status",
			sortable: false,
			flex: isSm ? 0.3 : undefined,
			width: isXs ? 100 : undefined,
			// width: 100,
			hideable: false,
		},
		{
			field: "keywords",
			headerName: "Keywords",
			sortable: false,
			flex: isSm ? 0.3 : undefined,
			width: isXs ? 100 : undefined,

			renderCell: (params: GridRenderCellParams) => {
				let keywordLeft = null;
				if (params.row?.keywords?.length > 1) {
					keywordLeft = params.row.keywords.length - 1;
				}
				return (
					<Box maxWidth={"150px"}>
						{params.row?.keywords?.length > 1 ? (
							<>
								<Chip
									variant="filled"
									label={params.row.keywords[0]}
									size="small"
									sx={{
										borderRadius: "4px",
										fontSize: "14px",
										marginRight: "10px",
									}}
								/>
								{/* <Chip
									variant="filled"
									label={params.row.keywords[1]}
									size="small"
									sx={{
										borderRadius: "4px",
										fontSize: "14px",
										marginRight: "10px",
									}}
								/> */}
								+ {keywordLeft}
							</>
						) : params.row?.keywords?.length <= 1 ||
						  params.row?.keywords?.length !== 0 ? (
							params.row?.keywords?.map(
								(data: string, index: number) => {
									return (
										<Chip
											key={index}
											variant="filled"
											label={data}
											size="small"
											sx={{
												borderRadius: "4px",
												fontSize: "14px",
												marginRight: "10px",
											}}
										/>
									);
								}
							)
						) : null}
					</Box>
				);
			},
		},
		{
			field: "tags",
			headerName: "Tags",
			sortable: false,
			flex: isSm ? 0.7 : undefined,
			width: isXs ? 190 : undefined,

			renderCell: (params: GridRenderCellParams) => {
				let keywordLeft = null;
				if (params.row?.tags?.length > 1) {
					keywordLeft = params.row.tags.length - 1;
				}
				return (
					<Box maxWidth={"150px"}>
						{params.row?.tags?.length > 1 ? (
							<>
								<Chip
									variant="filled"
									label={params.row.tags[0]}
									size="small"
									sx={{
										borderRadius: "4px",
										fontSize: "14px",
										marginRight: "10px",
									}}
								/>
								+ {keywordLeft}
							</>
						) : params.row?.tags?.length <= 1 ||
						  params.row?.tags?.length !== 0 ? (
							params.row?.tags?.map(
								(data: string, index: number) => {
									return (
										<Chip
											key={index}
											variant="filled"
											label={data}
											size="small"
											sx={{
												borderRadius: "4px",
												fontSize: "14px",
												marginRight: "10px",
											}}
										/>
									);
								}
							)
						) : null}
					</Box>
				);
			},
		},
		{
			field: "resourceQuestions",
			headerName: "Questions",
			sortable: false,
			flex: isSm ? 0.5 : undefined,
			width: isXs ? 150 : undefined,

			renderCell: (params: GridRenderCellParams) => {
				let keywordLeft = null;
				if (params.row?.resourceQuestions?.length > 1) {
					keywordLeft = params.row.resourceQuestions.length - 1;
				}
				return (
					<Box maxWidth={"150px"}>
						{params.row?.resourceQuestions?.length > 1 ? (
							<>
								<Chip
									variant="filled"
									label={params.row.resourceQuestions[0]}
									size="small"
									sx={{
										borderRadius: "4px",
										fontSize: "14px",
										marginRight: "10px",
									}}
								/>
								+ {keywordLeft}
							</>
						) : params.row?.resourceQuestions?.length <= 1 ||
						  params.row?.resourceQuestions?.length !== 0 ? (
							params.row?.resourceQuestions?.map(
								(data: string, index: number) => {
									return (
										<Chip
											key={index}
											variant="filled"
											label={data}
											size="small"
											sx={{
												borderRadius: "4px",
												fontSize: "14px",
												marginRight: "10px",
											}}
										/>
									);
								}
							)
						) : null}
					</Box>
				);
			},
		},
		{
			field: "failedReason",
			headerName: "FailedReason",
			sortable: false,
			width: 150,
			// flex: isSm ? 0.5 : undefined,
			// width: isXs ? 150 : undefined,
		},
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 180,
			hideable: false,
			getActions: (params) => [
				<GridActionsCellItem
					icon={<CreateOutlined fontSize="small" />}
					label="Edit"
					onClick={() => handleEditClick(params)}
					disabled={params.row.status === "Processing"}
				/>,
				// <GridActionsCellItem
				// 	icon={<DeleteOutlineOutlined fontSize="small" />}
				// 	label="Delete"
				// 	onClick={() => handleDelete(params)}
				// 	disabled={params.row.status === "Processing"}
				// />,
				<GridActionsCellItem
					icon={<ReplayIcon fontSize="small" />}
					label="Retry"
					onClick={() => handleRetry(params)}
					disabled={params.row.status !== "Failed"}
				/>,

				<GridActionsCellItem
					icon={<PreviewIcon fontSize="small" />}
					label="Generate Preview"
					onClick={() => generatePreviewRetry(params)}
					disabled={params.row.previewGenerated}
				/>,
			],
		},
	];

	useEffect(() => {
		getDatasetList(page, searchType, searchContent);
		getTagList(undefined, "Tag");
		getTagList(undefined, "Category");
		getTagList(undefined, "Keyword");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);
	return (
		<Box pt={3}>
			<TableTemplate
				search={
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-around",
							alignItems: "center",
							marginTop: "12px",
							marginBottom: "12px",
						}}
					>
						<Select
							label="Select"
							value={searchType}
							onChange={onSearchTypeChange}
							variant="standard"
							sx={{ width: "120px" }}
						>
							<MenuItem value={"all"}>{"All"}</MenuItem>
							{categories?.map((option) => (
								<MenuItem
									key={option.title}
									value={option.title}
								>
									{option.title}
								</MenuItem>
							))}
						</Select>

						<SearchFormWrapper>
							<NewSearchBar
								searchContent={searchContent}
								setSearchContent={setSearchContent}
								onSearch={handleSubmit}
								onCancelResearch={onCancelResearch}
								onChange={handleChange}
							/>
						</SearchFormWrapper>
					</Box>
				}
				list={datasets}
				dataGrid={
					<TeamDataGrid
						paginationData={pagination}
						list={datasets}
						columns={columns}
						page={page}
						handlePageChange={handlePageChange}
					/>
				}
				buttonGroups={null}
				emptyView={<EmptyListAnimation title="No content found" />}
				loading={isLoading}
			/>
			<DeleteContentModal />
		</Box>
	);
};
export default ContentsList;

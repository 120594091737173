import { Box } from "@material-ui/core";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Categories from "./containers/Categories";
import Content from "./containers/Content";
import CustomerSearch from "./containers/CustomerSearch";
import Dashboard from "./containers/Dashboard";
import Faqs from "./containers/Faqs";
import Keywords from "./containers/Keywords";
import PageNotFound from "./pages/PageNotFound";
import {
	MainPageTemplate,
	ResetPasswordPage,
	SignInPage,
	SignUpPage,
	VerifyEmailPage,
} from "./pages";
import Preview from "./containers/Preview";

function App() {
	return (
		<Box>
			<Routes>
				<Route path="/" element={<MainPageTemplate />}>
					<Route index element={<Dashboard />} />
					<Route path="dashboard" element={<Dashboard />} />
					<Route path="previews" element={<Preview />} />
					<Route path="contents" element={<Content />} />
					<Route path="categories" element={<Categories />} />
					<Route path="configuration" element={<Keywords />} />
					<Route path="history" element={<CustomerSearch />} />
					<Route path="faq" element={<Faqs />} />
				</Route>
				<Route path="/signup" element={<SignUpPage />} />
				<Route path="/login" element={<SignInPage />} />
				<Route path="/verify-email" element={<VerifyEmailPage />} />
				<Route path="/reset-password" element={<ResetPasswordPage />} />
				<Route path="*" element={<PageNotFound />} />
			</Routes>
			{/* <Footer /> */}
		</Box>
	);
}

export default App;

import { Box, styled } from "@mui/material";
import { FC } from "react";
import ResultItem from "./ResultItem";
import { PreviewsResponse } from "../../openapi";

type IItems = {
	items: Array<PreviewsResponse> | undefined;
};
const GetHostname = (url: string) => {
	const hostname = new URL(url).hostname.replace("www.", "");
	const capitalizedHostname = `${hostname.charAt(0).toUpperCase()}${
		hostname.slice(1).split(".")[0]
	}`;

	return capitalizedHostname;
};
const ResultItems: FC<IItems> = ({ items }) => {
	// console.log("items", items);
	return (
		<ResultItemsContainer>
			{items &&
				items.map((element: PreviewsResponse, index) => (
					<ResultItem
						key={Math.random() + index}
						icon={element?.favicon ?? ""}
						source={element.site_name ?? GetHostname(element.url)}
						url={element.url}
						title={element.title}
						description={element?.description || ""}
						tag={element?.tags ? element.tags[0] : ""}
					/>
				))}
		</ResultItemsContainer>
	);
};
const ResultItemsContainer = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	// alignItems: "center",
	gap: "8px",
	width: "100%",
	[theme.breakpoints.down("lg")]: {
		gap: "24px",
		// alignItems: "flex-start",
	},
	[theme.breakpoints.down("sm")]: {
		gap: "24px",
		// alignItems: "flex-start",
	},
}));
export default ResultItems;

import React from "react";
import {
	Autocomplete,
	Box,
	Chip,
	CircularProgress,
	createFilterOptions,
	Stack,
	styled,
	TextField,
	Typography,
} from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useAuthContext } from "../../contexts/Authcontext";
import { IError } from "../../hooks/useAuth";
import useDataset from "../../hooks/useDataset";
import usePreview from "../../hooks/usePreview";
// import useTag from "../../hooks/useTag";
// import { TagResponse } from "../../openapi";
import { clearDatasetDetils } from "../../redux/features/datasets/datasetsSlice";
import { clearModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { CONTENT_MODAL, PREVIEW_MODAL } from "../../utils/constants";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryOutlineButton from "../buttons/SecondaryOutLineButton";
import { FormField } from "../forms/FormField";
import { FormTextArea } from "../forms/FormTextArea";
import ModalTemplate from "./ModalTemplate";
import {
	PreviewsRequest,
	PreviewsResponse,
	PreviewsUpdateRequest,
} from "../../openapi";
const tags = [
	"VC",
	"Founder",
	"Accelerator",
	"Angel",
	"Platform",
	"Academic",
	"Operator",
];
const PreviewModal = () => {
	const dispatch = useDispatch();
	const { accessToken } = useAuthContext();

	const {
		createContent,
		getPreviewList,
		updatePreview,
		deletePreview,
		getPreviewsByUrls,
	} = usePreview();
	// const { createTag } = useTag();
	// const { createContent, updateDataset } = useDataset();
	// const [keywordsList, setKeywordsList] = useState<TagResponse[]>([]);
	// const [error, setError] = useState<IError | null>(null);
	// const [newKeyword, setNewKeyword] = useState<string>("");
	const { modalName } = useSelector((state: RootState) => state.modal);
	// const { tags } = useSelector((state: RootState) => state.tag);
	// const { dataset } = useSelector((state: RootState) => state.dataset);
	const { preview } = useSelector((state: RootState) => state.preview);
	const filter = createFilterOptions<any>();

	// let contentKeywords: TagResponse[] = [];
	let initialValues: PreviewsRequest | PreviewsUpdateRequest = {
		// id: 0,
		description: "",
		title: "",
		favicon: "",
		imageUrl: "",
		url: "",
		site_name: "",
		searchToolType: "Shome",
		tags: [""],
	};
	if (preview) {
		let temp: string[] = [];
		if (preview?.tags?.length) {
			preview?.tags?.map((item: string) => {
				for (let i = 0; i <= preview?.tags?.length; i++) {
					// if (item.id === Number(dataset.tags[i])) {
					temp.push(item);
					// }
				}
			});
		}

		initialValues = {
			id: preview.id,
			description: preview.description || "",
			title: preview.title || "",
			favicon: preview.favicon || "",
			imageUrl: preview.imageUrl || "",
			url: preview.url || "",
			site_name: preview.site_name || "",
			searchToolType: (preview.searchToolType as any) || "Shome",
			tags: preview.tags || [],
		};
	}
	const validationSchema = Yup.object().shape({
		title: Yup.string().min(3).required().label("Title"),
		description: Yup.string().optional().label("Content"),
		favicon: Yup.string().optional().label("Favicon"),
		// imageUrl: Yup.string().min(3).required().label("Image Url"),
		url: Yup.string().min(3).required().label("Url"),
		site_name: Yup.string().min(2).optional().label("Site Name"),
		tags: Yup.array().of(Yup.string()).optional().label("Tags"),
	});
	const handleClose = () => {
		dispatch(clearModalName());
		dispatch(clearDatasetDetils());
	};
	const onSubmit = (values: any, actions: any) => {
		// addDataset(values, actions);
		addPreview(values, actions);
	};
	const addPreview = async (values: any, actions: any) => {
		if (!accessToken) return;
		let tagIds: any[] = [];
		actions.setSubmitting(true);
		if (values.tags && values.tags.length) {
			values.tags.map((item: string) => {
				tagIds.push(item);
			});
		}
		let request: any = {
			description: values.description,
			title: values.title,
			favicon: values.favicon,
			imageUrl: values.imageUrl,
			url: values.url,
			site_name: values.site_name,
			searchToolType: values.searchToolType,
			tags: tagIds,
		};
		if (preview) {
			request.id = preview.id;
			updatePreview(request).then((r: unknown) => {
				const data = r as unknown as any;
				if (data) {
					actions.setSubmitting(false);
					handleClose();
				}
			});
		} else {
			createContent(request).then((r: unknown) => {
				const data = r as unknown as any;
				if (data) {
					actions.setSubmitting(false);
					handleClose();
				}
			});
		}
	};
	// const addDataset = async (values: any, actions: any) => {
	// 	if (!accessToken) return;
	// 	let tagIds: any[] = [];
	// 	actions.setSubmitting(true);
	// 	if (values.keywords && values.keywords.length) {
	// 		values.keywords.map((item: TagResponse) => {
	// 			tagIds.push(item.id);
	// 		});
	// 	}
	// 	let request = {
	// 		title: values.title,
	// 		bodyText: values.bodyText,
	// 		link: values.link,
	// 		summary: values.summary,
	// 		tags: tagIds,
	// 	};
	// 	if (dataset) {
	// 		updateDataset(request).then((r: unknown) => {
	// 			const data = r as unknown as any;
	// 			if (data) {
	// 				actions.setSubmitting(false);
	// 				handleClose();
	// 			}
	// 		});
	// 	} else {
	// 		createContent(request).then((r: unknown) => {
	// 			const data = r as unknown as any;
	// 			if (data) {
	// 				actions.setSubmitting(false);
	// 				handleClose();
	// 			}
	// 		});
	// 	}

	// 	// }
	// };
	// const filteredKeywords = (value: any[]) => {
	// 	return value.filter((item: any) => item.title !== "Create New Keyword");
	// };
	// let regex = /^[^,;]+$/;
	// const createNewKeyword = (formik: any) => {
	// 	let newKeywordList: (TagResponse | TagResponse[] | undefined)[] = [];
	// 	let isPresent: boolean = false;
	// 	if (keywordsList && keywordsList.length) {
	// 		keywordsList.map((item: any) => {
	// 			if (newKeyword.replace(/\s+/g, " ").trim() === item.title) {
	// 				isPresent = true;
	// 			}
	// 		});
	// 	}
	// 	if (formik.values.keywords.length) {
	// 		formik.values.keywords.map((item: TagResponse) => {
	// 			newKeywordList.push(item);
	// 		});
	// 	}

	// 	if (!regex.test(newKeyword)) {
	// 		setError({
	// 			message: "Invalid input.",
	// 			severity: "error",
	// 		});
	// 	} else if (isPresent) {
	// 		setError({
	// 			message: "Keyword already present.",
	// 			severity: "error",
	// 		});
	// 	} else {
	// 		setError(null);
	// 		createTag(newKeyword.replace(/\s+/g, " ").trim()).then(
	// 			(r: unknown) => {
	// 				const data = r as unknown as TagResponse;
	// 				if (data) {
	// 					newKeywordList.push(data);
	// 				}
	// 				formik.setFieldValue("keywords", newKeywordList);
	// 			}
	// 		);
	// 	}
	// };
	// useEffect(() => {
	// 	if (tags) {
	// 		setKeywordsList(tags);
	// 	}
	// }, [tags]);
	return (
		<ModalTemplate
			openModal={modalName === PREVIEW_MODAL}
			title={preview ? "Edit Preview" : "Add Preview"}
			dimension={1}
		>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="left"
				justifyContent="center"
			>
				<Wrapper>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{(formik) => {
							// return (
							// 	<Form style={{ width: "100%" }}>
							// 		<Box mb={4}>
							// 			<Label>Category</Label>
							// 			<TextField
							// 				fullWidth
							// 				variant="filled"
							// 				name={"category"}
							// 				label={"Fundraiser"}
							// 				size="small"
							// 				disabled
							// 				InputProps={{
							// 					disableUnderline: true,
							// 				}}
							// 			/>
							// 		</Box>
							// 		{dataset && (
							// 			<Box mb={3}>
							// 				<FormField
							// 					name={"title"}
							// 					label={"Title"}
							// 					placeholder="Enter title"
							// 				/>
							// 			</Box>
							// 		)}
							// 		<Box mb={4}>
							// 			<Label>Tags</Label>
							// 			<Autocomplete
							// 				multiple
							// 				size="medium"
							// 				fullWidth
							// 				//   limitTags={2}
							// 				value={formik.values.keywords}
							// 				filterSelectedOptions
							// 				id="multiple-limit-tags"
							// 				options={keywordsList}
							// 				loading={!keywordsList}
							// 				disableClearable
							// 				getOptionLabel={(option: any) =>
							// 					option.title
							// 				}
							// 				isOptionEqualToValue={(
							// 					option,
							// 					value
							// 				) => option.id === value.id}
							// 				forcePopupIcon={false}
							// 				onChange={(
							// 					event: React.SyntheticEvent,
							// 					newValue: any
							// 				) => {
							// 					if (
							// 						newValue &&
							// 						newValue.length
							// 					) {
							// 						newValue.map(
							// 							(item: any) => {
							// 								if (
							// 									item.inputValue
							// 								) {
							// 									createNewKeyword(
							// 										formik
							// 									);
							// 								} else {
							// 									formik.setFieldValue(
							// 										"keywords",
							// 										filteredKeywords(
							// 											newValue
							// 										)
							// 									);
							// 								}
							// 							}
							// 						);
							// 					} else {
							// 						formik.setFieldValue(
							// 							"keywords",
							// 							filteredKeywords(
							// 								newValue
							// 							)
							// 						);
							// 					}
							// 				}}
							// 				filterOptions={(
							// 					options,
							// 					params
							// 				) => {
							// 					const filtered = filter(
							// 						options,
							// 						params
							// 					);
							// 					const { inputValue } = params;
							// 					// Suggest the creation of a new value
							// 					const isExisting =
							// 						options.every(
							// 							(option) =>
							// 								inputValue
							// 									.toLocaleLowerCase()
							// 									.replace(
							// 										/\s+/g,
							// 										" "
							// 									)
							// 									.trim() ===
							// 								option.title.toLowerCase()
							// 						);
							// 					let isPresent: boolean = false;
							// 					if (
							// 						keywordsList &&
							// 						keywordsList.length
							// 					) {
							// 						keywordsList.map(
							// 							(item: any) => {
							// 								if (
							// 									inputValue
							// 										.toLocaleLowerCase()
							// 										.replace(
							// 											/\s+/g,
							// 											" "
							// 										)
							// 										.trim() ===
							// 									item.title.toLowerCase()
							// 								) {
							// 									isPresent =
							// 										true;
							// 								}
							// 							}
							// 						);
							// 					}
							// 					if (
							// 						inputValue !== "" &&
							// 						!isExisting &&
							// 						!isPresent
							// 					) {
							// 						filtered.unshift({
							// 							inputValue,
							// 							title: `Create New Keyword`,
							// 						});
							// 					} else if (
							// 						!filtered.length &&
							// 						inputValue !== ""
							// 					) {
							// 						filtered.push({
							// 							inputValue,
							// 							title: `Create New keyword`,
							// 						});
							// 					}
							// 					return filtered;
							// 				}}
							// 				onInputChange={(event: any) => {
							// 					setError(null);
							// 					setNewKeyword(
							// 						event?.target.value
							// 					);
							// 				}}
							// 				renderTags={(value, getTagProps) =>
							// 					filteredKeywords(value).map(
							// 						(option, index) => {
							// 							if (
							// 								!option.inputValue
							// 							) {
							// 								return (
							// 									<Chip
							// 										variant="filled"
							// 										label={
							// 											option.title
							// 										}
							// 										size="small"
							// 										sx={{
							// 											borderRadius:
							// 												"3px",
							// 											fontSize:
							// 												"14px",
							// 										}}
							// 										{...getTagProps(
							// 											{
							// 												index,
							// 											}
							// 										)}
							// 									/>
							// 								);
							// 							}
							// 						}
							// 					)
							// 				}
							// 				renderInput={(params) => (
							// 					<TextField
							// 						{...params}
							// 						name="keywords"
							// 						size="medium"
							// 						placeholder={
							// 							!formik.values.keywords
							// 								.length
							// 								? "Select or create keywords"
							// 								: ""
							// 						}
							// 						value={
							// 							formik.values.keywords
							// 						}
							// 						error={
							// 							formik.touched
							// 								.keywords &&
							// 							Boolean(
							// 								formik.errors
							// 									.keywords
							// 							)
							// 						}
							// 						sx={{
							// 							"& fieldset": {
							// 								borderRadius: "8px",
							// 							},
							// 						}}
							// 					/>
							// 				)}
							// 			/>
							// 			<ErrorMessage name={"keywords"}>
							// 				{(msg) => (
							// 					<ErrorMessageWapper>
							// 						{msg}
							// 					</ErrorMessageWapper>
							// 				)}
							// 			</ErrorMessage>
							// 		</Box>
							// 		<FormTextArea
							// 			name={"bodyText"}
							// 			label={"Content"}
							// 			placeholder="Enter content"
							// 		/>
							// 		{dataset && (
							// 			<FormTextArea
							// 				name={"summary"}
							// 				label={"Summary"}
							// 				placeholder="Enter summary"
							// 			/>
							// 		)}
							// 		<Box mb={3}>
							// 			<FormField
							// 				name={"link"}
							// 				label={"Link"}
							// 				placeholder="Enter link"
							// 			/>
							// 		</Box>
							// 		<Stack direction="row" spacing={2}>
							// 			<PrimaryButton
							// 				text={dataset ? "Save" : "Add New"}
							// 				style={{ width: "100%" }}
							// 				disabled={formik.isSubmitting}
							// 				type="submit"
							// 				icon={
							// 					formik.isSubmitting ? (
							// 						<CircularProgress
							// 							size="1rem"
							// 							color="inherit"
							// 						/>
							// 					) : null
							// 				}
							// 			/>
							// 			<SecondaryOutlineButton
							// 				style={{ width: "100%" }}
							// 				text="Close"
							// 				onClick={handleClose}
							// 			/>
							// 		</Stack>
							// 	</Form>
							// );
							return (
								<Form style={{ width: "100%" }}>
									<Box mb={4}>
										<FormField
											name={"title"}
											label={"Title"}
											placeholder="Enter title"
										/>
									</Box>
									<Box mb={4}>
										<FormField
											name={"favicon"}
											label={"Favicon"}
											placeholder="Enter favicon"
										/>
									</Box>
									{/* <Box mb={4}>
										<FormField
											name={"imageUrl"}
											label={"Image Url"}
											placeholder="Enter image url"
										/>
									</Box> */}

									<Box mb={4}>
										{/* <FormField
											name={"description"}
											label={"Description"}
											placeholder="Enter description"
										/> */}
										<FormTextArea
											name={"description"}
											label={"Description"}
											placeholder="Enter Description"
										/>
									</Box>
									<Box mb={4}>
										<FormField
											name={"url"}
											label={"Url"}
											placeholder="Enter url"
										/>
									</Box>
									<Box mb={4}>
										<FormField
											name={"site_name"}
											label={"Site Name"}
											placeholder="Enter site name"
										/>
									</Box>
									{/* <Box mb={4}>
										<FormField
											name={"searchToolType"}
											label={"Search Tool Type"}
											placeholder="Enter search tool type"
										/>
									</Box> */}
									<Box mb={4}>
										<Label>Tags</Label>
										<Autocomplete
											multiple
											size="medium"
											fullWidth
											//   limitTags={2}
											value={formik.values.tags}
											filterSelectedOptions
											id="multiple-limit-tags"
											options={tags}
											loading={!tags}
											disableClearable
											getOptionLabel={(option: any) =>
												option
											}
											isOptionEqualToValue={(
												option,
												value
											) => option === value}
											forcePopupIcon={false}
											onChange={(
												event: React.SyntheticEvent,
												newValue: any
											) => {
												formik.setFieldValue(
													"tags",
													newValue
												);
											}}
											filterOptions={(
												options,
												params
											) => {
												const filtered = filter(
													options,
													params
												);
												const { inputValue } = params;
												// Suggest the creation of a new value
												const isExisting =
													options.every(
														(option) =>
															inputValue
																.toLocaleLowerCase()
																.replace(
																	/\s+/g,
																	" "
																)
																.trim() ===
															option.toLowerCase()
													);
												let isPresent: boolean = false;
												if (tags && tags.length) {
													tags.map((item: any) => {
														if (
															inputValue
																.toLocaleLowerCase()
																.replace(
																	/\s+/g,
																	" "
																)
																.trim() ===
															item.toLowerCase()
														) {
															isPresent = true;
														}
													});
												}
												if (
													inputValue !== "" &&
													!isExisting &&
													!isPresent
												) {
													filtered.unshift(
														inputValue
													);
												} else if (
													!filtered.length &&
													inputValue !== ""
												) {
													filtered.push(inputValue);
												}
												return filtered;
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													name="tags"
													size="medium"
													placeholder={
														!formik.values.tags
															.length
															? "Select or create tags"
															: ""
													}
													value={formik.values.tags}
													error={
														formik.touched.tags &&
														Boolean(
															formik.errors.tags
														)
													}
													sx={{
														"& fieldset": {
															borderRadius: "8px",
														},
													}}
												/>
											)}
										/>
										<ErrorMessage name={"tags"}>
											{(msg) => (
												<ErrorMessageWapper>
													{msg}
												</ErrorMessageWapper>
											)}
										</ErrorMessage>
									</Box>
									<Stack direction="row" spacing={2}>
										<PrimaryButton
											text={preview ? "Save" : "Add New"}
											style={{ width: "100%" }}
											disabled={formik.isSubmitting}
											type="submit"
											icon={
												formik.isSubmitting ? (
													<CircularProgress
														size="1rem"
														color="inherit"
													/>
												) : null
											}
										/>
										<SecondaryOutlineButton
											style={{ width: "100%" }}
											text="Close"
											onClick={handleClose}
										/>
									</Stack>
								</Form>
							);
						}}
					</Formik>
				</Wrapper>
			</Box>
		</ModalTemplate>
	);
};
export default PreviewModal;

export const Wrapper = styled(Box)(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	background: "#fff",
	borderRadius: "5px",
	boxSizing: "border-box",
	flex: 1,
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		justifyContent: "flex-start",
	},
}));

export const Label = styled(Typography)(({ theme }) => ({
	fontSize: "16px",
	fontWeight: "500",
	color: "#2C3659",
	lineHeight: "21px",
	marginBottom: "5px",
}));

const ErrorMessageWapper = styled(Box)(({ theme }) => ({
	color: "#F2323F",
	fontSize: "12px",
	//paddingLeft: "12px",
	fontWeight: 400,
	marginTop: "5px",
}));

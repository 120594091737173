import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useAuthContext } from "../contexts/Authcontext";
import {
  saveLoading,
  storeCustomers,
  storePaginationDetails,
} from "../redux/features/customers/cutomersSlice";
import { getUserService } from "../utils/apiHelpers";
import { getOffset } from "../utils";
import { PAGE_LIMIT } from "../utils/constants";
import * as XLSX from "xlsx";
import { UserResponse } from "../openapi";

// Utility function to handle string to ArrayBuffer conversion
const s2ab = (s: string) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

// Function to generate and download the XLS file
const generateExcel = async (allCustomers: UserResponse[]) => {
  // Prepare data for the sheet (only include firstName, lastName, email, and subscriptionType)
  const sheetData = allCustomers.map((user) => ({
    "First Name": user.firstName,
    "Last Name": user.lastName || "", // Handle missing last names
    Email: user.email,
    "Subscription Type": user.subscriptionType,
  }));

  // Create a new workbook and a new sheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(sheetData);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Users");

  // Generate a binary string for the workbook
  const file = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

  // Convert the binary string to a Blob and download the file
  const blob = new Blob([s2ab(file)], {
    type: "application/octet-stream",
  });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "users.xlsx";
  link.click();
  return;
};

const useCustomer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { accessToken } = useAuthContext();

  const getCustomersList = async (page?: number) => {
    let offset = "0"
    if (accessToken) {
      if (page) {
        offset = getOffset(page);
      }
      setIsLoading(true);
      saveLoading(true);
      try {
        const service = await getUserService(accessToken);
        const response = await service.getCustomers(page ? PAGE_LIMIT : "1000", offset);
        if (response.status === 200 || response.status === 201) {
          // console.log('users', response.data.users)
          dispatch(storeCustomers(response.data.users));
          dispatch(storePaginationDetails(response.data));
          setIsLoading(false);
          saveLoading(false);
        }
      } catch (error: any) {
        setIsLoading(false);
        saveLoading(false);
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        // if (error.response.status === 500) {
        //   navigate("*");
        // }
      }
    }
  };
  const getAllCustomers = async () => {
    if (accessToken) {
      setIsLoading(true);
      try {
        const service = await getUserService(accessToken);
        const response = await service.getCustomers("1000", "0");
        if (response.status === 200 || response.status === 201) {
          await generateExcel(response.data.users);
          setIsLoading(false);
        }
      } catch (error: any) {
        setIsLoading(false);
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
      }
    }
  }
  return {
    getCustomersList,
    getAllCustomers,
    isLoading,
  };
};

export default useCustomer;

import { Box, CircularProgress, Stack, styled } from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useAuthContext } from "../../contexts/Authcontext";
import useTag from "../../hooks/useTag";
import { clearModalName } from "../../redux/features/modal/modalSlice";
import { clearTagDetils } from "../../redux/features/tags/tagsSlice";
import { RootState } from "../../redux/store";
import { TAG_MODAL } from "../../utils/constants";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryOutlineButton from "../buttons/SecondaryOutLineButton";
import { FormField } from "../forms/FormField";
import ModalTemplate from "./ModalTemplate";
import { TagUpdateRequestTypeEnum } from "../../openapi";
import { Select, Typography } from "@material-ui/core";
// import { Label } from "@mui/icons-material";

// const TagModal = () => {
// 	const dispatch = useDispatch();
// 	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
// 	const { createLoading: isLoading, createTag, updateTag } = useTag();
// 	const { accessToken } = useAuthContext();
// 	const { modalName } = useSelector((state: RootState) => state.modal);
// 	const { tag } = useSelector((state: RootState) => state.tag);

// 	let initialValues = {
// 		title: "",
// 		type: "",
// 	};
// 	if (tag) {
// 		initialValues = {
// 			title: tag.title,
// 			type: tag.type as TagUpdateRequestTypeEnum,
// 		};
// 	}
// 	const validationSchema = Yup.object().shape({
// 		title: Yup.string().min(2).required().label("Keyword"),
// 		type: Yup.string().required().label("Type"),
// 	});
// 	const handleClose = () => {
// 		dispatch(clearModalName());
// 		dispatch(clearTagDetils());
// 	};
// 	const onSubmit = (values: any, actions: any) => {
// 		addTag(values, actions);
// 	};

// 	const addTag = async (values: any, actions: any) => {
// 		if (!accessToken) return;
// 		setIsSubmitting(true);
// 		if (!tag) {
// 			const res = await createTag(
// 				values.title,
// 				values.type as TagUpdateRequestTypeEnum
// 			);
// 			if (res) {
// 				handleClose();
// 				setIsSubmitting(false);
// 			}
// 		} else {
// 			const res = await updateTag(values);
// 			if (res) {
// 				handleClose();
// 				setIsSubmitting(false);
// 			}
// 		}
// 	};
// 	return (
// 		<ModalTemplate
// 			openModal={modalName === TAG_MODAL}
// 			title={tag ? "Edit Tag" : "Add Tag"}
// 		>
// 			<Box
// 				display="flex"
// 				flexDirection="column"
// 				alignItems="left"
// 				justifyContent="center"
// 			>
// 				<Wrapper>
// 					<Formik
// 						initialValues={initialValues}
// 						validationSchema={validationSchema}
// 						onSubmit={onSubmit}
// 					>
// 						{(formik) => {
// 							return (
// 								<Form style={{ width: "100%" }}>
// 									<FormField
// 										name={"title"}
// 										label={"Keyword"}
// 										placeholder="Enter keyword"
// 									/>
// 									{!tag && (
// 										<Box>
// 											<Typography>
// 												{"Select Type"}
// 											</Typography>
// 											<Select
// 												name="type"
// 												native
// 												label="Type"
// 												// variant="outlined"
// 												value={formik.values.type}
// 												onChange={formik.handleChange}
// 											>
// 												<option
// 													value={
// 														TagUpdateRequestTypeEnum.Keyword
// 													}
// 												>
// 													Keyword
// 												</option>
// 												<option
// 													value={
// 														TagUpdateRequestTypeEnum.Category
// 													}
// 												>
// 													Category
// 												</option>
// 												<option
// 													value={
// 														TagUpdateRequestTypeEnum.Tag
// 													}
// 												>
// 													Tag
// 												</option>
// 											</Select>
// 										</Box>
// 									)}
// 									<Stack direction="row" spacing={2} mt={4}>
// 										<PrimaryButton
// 											text={tag ? "Save" : "Add New"}
// 											style={{ width: "100%" }}
// 											disabled={formik.isSubmitting}
// 											type="submit"
// 											icon={
// 												formik.isSubmitting ? (
// 													<CircularProgress
// 														size="1rem"
// 														color="inherit"
// 													/>
// 												) : null
// 											}
// 										/>
// 										<SecondaryOutlineButton
// 											style={{ width: "100%" }}
// 											text="Close"
// 											onClick={handleClose}
// 										/>
// 									</Stack>
// 								</Form>
// 							);
// 						}}
// 					</Formik>
// 				</Wrapper>
// 			</Box>
// 		</ModalTemplate>
// 	);
// };
const TagModal = () => {
	const dispatch = useDispatch();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const { createTag, updateTag } = useTag();
	const { accessToken } = useAuthContext();
	const { modalName } = useSelector((state: RootState) => state.modal);
	const { tag } = useSelector((state: RootState) => state.tag);

	let initialValues = {
		title: "",
		type: "",
		vectorClass: "",
	};
	if (tag) {
		initialValues = {
			title: tag.title,
			type: tag.type as TagUpdateRequestTypeEnum,
			vectorClass:
				tag.type === TagUpdateRequestTypeEnum.Category
					? tag.vectorClass
					: "",
		};
	}

	const validationSchema = Yup.object().shape({
		title: Yup.string().min(2).required().label("Keyword"),
		type: Yup.string().required().label("Type"),
		vectorClass: Yup.string()
			.when("type", {
				is: TagUpdateRequestTypeEnum.Category,
				then: (schema) => schema.required("Vector Class is required"),
				otherwise: (schema) => schema.notRequired(),
			})
			.label("Vector Class"),
	});

	const handleClose = () => {
		dispatch(clearModalName());
		dispatch(clearTagDetils());
	};

	const onSubmit = (values: any, actions: any) => {
		addTag(values, actions);
	};

	const addTag = async (values: any, actions: any) => {
		console.log("values", values);
		if (!accessToken) return;
		setIsSubmitting(true);
		if (!tag) {
			let req = {
				title: values.title as string,
				type: values.type as TagUpdateRequestTypeEnum,
				vectorClass: values.vectorClass as string,
			};
			const res = await createTag(req);
			if (res) {
				handleClose();
				setIsSubmitting(false);
			}
		} else {
			let req = {
				id: tag.id,
				title: values.title as string,
				type: values.type as TagUpdateRequestTypeEnum,
				vectorClass: values.vectorClass as string,
			};
			const res = await updateTag(req);
			if (res) {
				handleClose();
				setIsSubmitting(false);
			}
		}
	};

	return (
		<ModalTemplate
			openModal={modalName === TAG_MODAL}
			title={tag ? "Edit Tag" : "Add Tag"}
		>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="left"
				justifyContent="center"
			>
				<Wrapper>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
						isSubmitting={isSubmitting}
					>
						{(formik) => {
							return (
								<Form style={{ width: "100%" }}>
									<FormField
										name="title"
										label="Keyword"
										placeholder="Enter keyword"
									/>
									<Box
										sx={{
											marginBottom: "4px",
											minHeight: "90px",
										}}
									>
										<Typography>Select Type</Typography>
										<Select
											name="type"
											native
											label="Type"
											value={formik.values.type}
											onChange={formik.handleChange}
										>
											<option
												value={
													TagUpdateRequestTypeEnum.Keyword
												}
											>
												Keyword
											</option>
											<option
												value={
													TagUpdateRequestTypeEnum.Category
												}
											>
												Category
											</option>
											<option
												value={
													TagUpdateRequestTypeEnum.Tag
												}
											>
												Tag
											</option>
										</Select>
									</Box>
									{formik.values.type ===
										TagUpdateRequestTypeEnum.Category && (
										<FormField
											name="vectorClass"
											label="Vector Class"
											placeholder="Enter vector class"
										/>
									)}
									<Stack direction="row" spacing={2} mt={4}>
										<PrimaryButton
											text={tag ? "Save" : "Add New"}
											style={{ width: "100%" }}
											disabled={formik.isSubmitting}
											type="submit"
											icon={
												formik.isSubmitting ? (
													<CircularProgress
														size="1rem"
														color="inherit"
													/>
												) : null
											}
										/>
										<SecondaryOutlineButton
											style={{ width: "100%" }}
											text="Close"
											onClick={handleClose}
										/>
									</Stack>
								</Form>
							);
						}}
					</Formik>
				</Wrapper>
			</Box>
		</ModalTemplate>
	);
};

export default TagModal;

export const Wrapper = styled(Box)(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	background: "#fff",
	borderRadius: "5px",
	boxSizing: "border-box",
	flex: 1,
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		justifyContent: "flex-start",
	},
}));

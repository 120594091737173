import React from "react";
// import { useState } from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
// import SearchIcon from "@mui/icons-material/Search";
// import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import useAutocomplete from "@mui/material/useAutocomplete";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { Box, Button } from "@mui/material";
import { theme } from "../../theme";
import CustomSearchIcon from "../svg-icon/SearchIcon";
import CrossIcon from "../svg-icon/CrossIcon";

const Search = styled("div")(({ theme }) => ({
	position: "relative",
	display: "flex",
	alignItems: "center",
	gap: "10px",
	color: "#4d4e53",
	flex: 1,
	margin: "auto 0",
	padding: "8px 0px",
	[theme.breakpoints.down("lg")]: {
		flexWrap: "wrap",
	},
}));

const SearchIconWrapper = styled("div")(() => ({
	height: "100%",
	position: "absolute",
	// padding: "0 18px",
	paddingLeft: "18px",
	pointerEvents: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}));

const CloseIconWrapper = styled("div")(() => ({
	position: "absolute",
	height: "100%",
	right: "13px",
	top: 0,
	display: "flex",
	alignItems: "center",
	justifyContent: "center",

	"&:hover": {
		cursor: "pointer",
	},
}));

const StyledInputBase = styled(InputBase)(() => ({
	width: "100%",
	paddingLeft: "50px",
	paddingRight: "40px",
	paddingTop: "0px",
	paddingBottom: "0px",
	height: "17px",
	color: "#4D4E53",
	fontSize: "14px",
	fontWeight: 500,
	lineHeight: "17px",
}));

const Listbox = styled("ul")(() => ({
	width: "100%",
	margin: 0,
	padding: 0,
	zIndex: 1,
	position: "absolute",
	listStyle: "none",
	backgroundColor: "#f1f1f1",
	overflow: "auto",
	maxHeight: 200,
	borderRadius: "0 0 10px 10px",
	border: "1px solid rgba(0,0,0,.25)",
	"& li": { textAlign: "left", paddingLeft: "10px" },
	[`& li.${autocompleteClasses.focused}`]: {
		backgroundColor: "#4a8df6",
		color: "white",
		cursor: "pointer",
	},
	"& li:active": {
		backgroundColor: "#2977f5",
		color: "white",
	},
}));

const SearchContainer = styled(Box)(({ theme }) => ({
	borderRadius: "8px",
	backgroundColor: "#f5f7fc",
	// boxShadow: "0 1px 2px 1px rgba(0, 0, 0, 0.07)",
	alignSelf: "start",
	display: "flex",
	gap: "16px",
	fontSize: "14px",
	fontWeight: 500,
	minWidth: "605px",
	border: "2px solid #ffffff",
	"&:hover": {
		border: "2px solid #000000",
	},
	"&:focus": {
		border: "2px solid #000000",
	},
	[theme.breakpoints.down("lg")]: {
		minWidth: "80%",
	},
	[theme.breakpoints.down("sm")]: {
		// flexWrap: "wrap",
		minWidth: "100%",
	},
}));

const SearchButton = styled(Button)({
	fontFamily: "Inter",
	fontSize: "14px",
	fontWeight: 500,
	borderRadius: "8px",
	backgroundColor: "#3677FF",
	color: "#ffff",
	// whiteSpace: "nowrap",
	lineHeight: "24px",
	padding: "8px 16px",
	// border: "none",
	cursor: "pointer",
	width: "79px",
	textTransform: "none",
	"&:hover": {
		backgroundColor: "#2851a3",
	},
	boxShadow: "none",
	// [theme.breakpoints.down("lg")]: {
	// 	visibility: "hidden",
	// 	display: "none",
	// },
	// [theme.breakpoints.down("sm")]: {
	// 	visibility: "hidden",
	// 	display: "none",
	// },
	// "@media (max-width: 991px)": {
	// 	whiteSpace: "initial",
	// },
});
const NewSearchBar = ({
	value,
	width,
	searchContent,
	setSearchContent,
	onChange,
	placeholder,
	height,
	onCancelResearch,
	onSearch,
	className,
	style,
	disabled,
	options,
}: {
	value?: string;
	width?: string;
	searchContent: string;
	setSearchContent: (value: string) => void;
	onChange?: (value: string) => void;
	placeholder?: string;
	height?: string;
	onCancelResearch?: (value: string) => void;
	onSearch?: (value?: string) => void;
	className?: string;
	style?: React.CSSProperties;
	disabled?: boolean;
	options?: string[];
}) => {
	// const [internalValue, setInternalValue] = useState(value || "");

	const {
		getRootProps,
		getInputProps,
		getListboxProps,
		getOptionProps,
		groupedOptions,
	} = useAutocomplete({
		id: "use-autocomplete",
		freeSolo: true,
		options: options || [],
		getOptionLabel: (option) => option,
		filterOptions: (options) => {
			if (options === undefined) return [];
			let newOptions = options.filter((option) => {
				return option
					.toLowerCase()
					.includes(searchContent.toLowerCase());
			});
			return newOptions.slice(0, 5);
		},
	});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchContent(e.target.value);
		if (onChange) {
			onChange(e.target.value);
		}
	};

	const handleCancel = () => {
		setSearchContent("");
		if (onCancelResearch) {
			onCancelResearch(searchContent);
		}
	};

	const handleClickOption = (e: any) => {
		// console.log("searchQuestion", e.target.textContent);
		// console.log("searchQuestion", searchContent);
		const query =
			e.target.textContent === "Search" || !e.target.textContent
				? searchContent
				: e.target.textContent;
		if (!query) return;
		if (query === "Search...") return;
		if (!query) return;
		setSearchContent(query);
		if (onChange) {
			onChange(query);
		}
		if (onSearch) return onSearch(query);
	};

	const handleKeyUp = (e: any) => {
		if (e.keyCode === 13 || (e.code === "Enter" && onSearch)) {
			if (onSearch) onSearch();
		} else if (e.keyCode === 27 || e.code === "Escape") {
			handleCancel();
		}
	};

	return (
		<>
			<SearchContainer>
				<Search
					{...getRootProps()}
					key={"SearchBarComponent-root"}
					style={{
						...style,
						width: width || "300px",
						height: height || "40px",
					}}
					className={`SearchBarComponent-root ${
						className ? className : null
					}`}
				>
					<SearchIconWrapper>
						<CustomSearchIcon />
					</SearchIconWrapper>
					<StyledInputBase
						inputProps={{
							...getInputProps(),
							style: { padding: "0px" },
							onChange: handleChange,
							value: searchContent,
						}}
						placeholder={placeholder || "Search..."}
						onKeyUp={handleKeyUp}
						disabled={disabled}
					/>
					{searchContent ? (
						<CloseIconWrapper onClick={handleCancel}>
							{/* <CloseIcon /> */}
							<CrossIcon />
						</CloseIconWrapper>
					) : null}

					{groupedOptions.length > 0 && searchContent.length ? (
						<Listbox {...getListboxProps()}>
							{groupedOptions.map((option, index) => (
								<li
									{...getOptionProps({
										option,
										index,
									} as any)}
									onClick={handleClickOption}
								>
									<strong>{option as any}</strong>
								</li>
							))}
						</Listbox>
					) : null}
				</Search>
			</SearchContainer>
			<SearchButton variant="contained" onClick={handleClickOption}>
				Search
			</SearchButton>
		</>
	);
};

NewSearchBar.propTypes = {
	// custom top-level class
	className: PropTypes.string,
	// changes the default width of component
	width: PropTypes.node,
	// changes the default height of component
	height: PropTypes.node,
	// override the placeholder
	placeholder: PropTypes.string,
	// value of input text field
	value: PropTypes.string,
	// fired when input value changes
	onChange: PropTypes.func,
	// fired when the search is canceled
	onCancelResearch: PropTypes.func,
	// fired when press enter
	onSearch: PropTypes.func,
	// override styles of the root element
	style: PropTypes.object,
	// disable text field
	disabled: PropTypes.bool,
	//options of autocomplete suggests
	options: PropTypes.array,
};

export default NewSearchBar;

import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useAuthContext } from "../contexts/Authcontext";
import { getOffset } from "../utils";
import { clearModalName } from "../redux/features/modal/modalSlice";
import { getPreviewService } from "../utils/apiHelpers";
import { PAGE_LIMIT } from "../utils/constants";

import { PreviewsUpdateRequest, PreviewsRequest } from "../openapi";
import { clearPreviewDetils, editPreview, saveLoading, storePreviews, storePreviewDetails, storePaginationDetails } from "../redux/features/previews/previewSlice";

// import { DatasetCreateRequest } from "../openapi";
// import { DatasetUpdateReq } from "../openapi/models/dataset-update-req";
// import {
//   clearDatasetDetils,
//   editDataset,
//   // saveLoading,
//   storeDatasetDetails,
//   storeDatasets,
//   // storePaginationDetails,
// } from "../redux/features/datasets/datasetsSlice";

const usePreview = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { accessToken } = useAuthContext();
  // const previewList = useSelector((state: RootState) => state.preview.previewList);
  const getPreviewList = async (page: number, url?: string) => {
    let offset = getOffset(page);
    if (accessToken) {
      dispatch(saveLoading(true));
      try {
        const service = await getPreviewService(accessToken);
        const response = url && url.length > 0 ? await service.getPreviewsLikeByString(PAGE_LIMIT, offset, url) : await service.getPreviewssForCustomer(PAGE_LIMIT, offset);
        if (response.status === 200 || response.status === 201) {
          dispatch(storePreviews(response.data.previewList));
          dispatch(storePaginationDetails(response.data));
          dispatch(saveLoading(false));
        }
        // return response.data
      } catch (error: any) {
        dispatch(saveLoading(false));
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        // if (error.response.status === 500) {
        //   navigate("*");
        // }
      }
    }
  };
  const getPreviewsByUrls = async (url: string) => {
    if (accessToken) {
      dispatch(saveLoading(true));
      try {
        const service = await getPreviewService(accessToken);
        const response = await service.getPreviewsByUrls([url])
        if (response.status === 200 || response.status === 201) {
          dispatch(storePreviewDetails(response.data.previews[0]));
          dispatch(saveLoading(false));
          return response.data.previews[0];
        }
      } catch (error: any) {
        dispatch(saveLoading(false));
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        // if (error.response.status === 500) {
        //   navigate("*");
        // }
      }
    }
  };
  const createContent = async (value: any) => {
    if (accessToken && value) {
      dispatch(saveLoading(true));
      const createRequest: PreviewsRequest = {
        'description': value.description ?? "",
        'title': value.title ?? "",
        'favicon': value.favicon ?? "",
        'imageUrl': value.imageUrl ?? "",
        'url': value.url ?? "",
        'site_name': value.site_name ?? "",
        'searchToolType': value.searchToolType ?? "",
        'tags': value.tags ?? [],
      };
      try {
        const service = await getPreviewService(accessToken);
        const response = await service.createPreviews(createRequest);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Content created successfully", {
            variant: "success",
          });
          dispatch(saveLoading(false));
          //dispatch(createTags(true));
          getPreviewList(1);
          return response.data;
        }
      } catch (error: any) {
        dispatch(saveLoading(false));

        enqueueSnackbar(error?.response?.data?.message.toString() ?? error.toString(), {
          variant: "error",
        });
      }
    }
  };
  const updatePreview = async (values: any) => {
    if (accessToken) {
      dispatch(saveLoading(true));
      const updateRequest: PreviewsUpdateRequest = {
        'id': values.id,
        'description': values.description ?? "",
        'title': values.title ?? "",
        'favicon': values.favicon ?? "",
        'imageUrl': values.imageUrl ?? "",
        'url': values.url ?? "",
        'site_name': values.site_name ?? "",
        'searchToolType': values.searchToolType ?? "",
        'tags': values.tags ?? [],
      };
      try {
        const service = await getPreviewService(accessToken);
        const response = await service.updatePreviews(updateRequest);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Content updated successfully", {
            variant: "success",
          });
          dispatch(saveLoading(false));
          dispatch(editPreview(response.data));
          getPreviewList(1);
          return response.data;
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        dispatch(saveLoading(false));
      }
    }
  };
  const deletePreview = async (id: number) => {
    if (accessToken) {
      dispatch(saveLoading(true));
      try {
        const productService = await getPreviewService(accessToken);
        const response = await productService.deletePreviews(id);
        if (response.status === 200) {
          enqueueSnackbar("Content deleted successfully", {
            variant: "success",
          });
          getPreviewList(1);
          dispatch(saveLoading(false));
          dispatch(clearModalName());
          dispatch(clearPreviewDetils());
          return response;
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        dispatch(saveLoading(false));
      }
    }
  };

  return {
    createContent,
    getPreviewList,
    updatePreview,
    deletePreview,
    getPreviewsByUrls,
  };
};
export default usePreview;

import { Auth } from "aws-amplify";
import jwt_decode from "jwt-decode";
import { saveInLocalStorage } from ".";
import {
  CategoriesApi,
  Configuration,
  DatasetsApi,
  TagsApi,
  UsersApi,
  // PreviewsApi,
} from "../openapi";
import { PreviewsApi } from "../openapi/api/previews-api";
import { ChatHistoryApi } from "../openapi/api/chat-history-api";
import { CreateUsersApi } from "../openapi/api/create-users-api";
import { FaqApi } from "../openapi/api/faq-api";
//import { DashboardApi } from "../openapi/api/dashboard-api";
//import { FacebookApi } from "../openapi/api/facebook-api";

export default class ApiConfiguration extends Configuration {
  constructor(accessToken: string) {
    super();
    this.basePath = process.env.REACT_APP_BASE_URL;
    this.apiKey = accessToken;
  }
}

class ApiConfigurationWithoutAccessToken extends Configuration {
  constructor() {
    super();
    this.basePath = process.env.REACT_APP_BASE_URL;
  }
}

const getAccessJwtToken = async () => {
  const session = await Auth.currentSession();
  return session.getAccessToken().getJwtToken();
};

const isTokenExpired = (token: string) => {
  const dateNow = new Date();
  const decodedTokenData: any = jwt_decode(token);
  if (
    decodedTokenData &&
    decodedTokenData.exp < Math.floor(dateNow.getTime() / 1000)
  ) {
    return true;
  }
  return false;
};

const getServiceConfig = async (accessToken: string) => {
  if (isTokenExpired(accessToken)) {
    //Get new token if expired
    accessToken = await getAccessJwtToken();
    saveInLocalStorage("accessToken", accessToken);
    window.dispatchEvent(new Event("storage"));
  }
  let serviceConfiguration: ApiConfiguration = new ApiConfiguration(
    accessToken
  );
  return serviceConfiguration;
};

let usersApi: UsersApi;
let tagsApi: TagsApi;
let categoriesApi: CategoriesApi;
let datasetsApi: DatasetsApi;
let chatHistoryApi: ChatHistoryApi;
let createUserApi: CreateUsersApi;
let faqApi: FaqApi;
let previewsApi: PreviewsApi;

export const getUserService = async (accessToken: string) => {
  usersApi = new UsersApi(await getServiceConfig(accessToken));
  return usersApi;
};

export const getTagService = async (accessToken: string) => {
  tagsApi = new TagsApi(await getServiceConfig(accessToken));
  return tagsApi;
};

export const getCategoryService = async (accessToken: string) => {
  categoriesApi = new CategoriesApi(await getServiceConfig(accessToken));
  return categoriesApi;
};

export const getDatasetService = async (accessToken: string) => {
  datasetsApi = new DatasetsApi(await getServiceConfig(accessToken));
  return datasetsApi;
};

export const getPreviewService = async (accessToken: string) => {
  previewsApi = new PreviewsApi(await getServiceConfig(accessToken));
  return previewsApi;
}

export const getChatHistoryService = async (accessToken: string) => {
  chatHistoryApi = new ChatHistoryApi(await getServiceConfig(accessToken));
  return chatHistoryApi;
};

export const getFaqService = async (accessToken: string) => {
  faqApi = new FaqApi(await getServiceConfig(accessToken));
  return faqApi;
};

export const getCreateUserService = async () => {
  createUserApi = new CreateUsersApi(new ApiConfigurationWithoutAccessToken());
  return createUserApi;
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PreviewsList, PreviewsResponse } from "../../../openapi/models";


export interface PreviewStae {
  previewList: Array<PreviewsResponse> | undefined;
  pagination: PreviewsList | undefined;
  preview: PreviewsResponse | undefined;
  isLoading: boolean;
}

const initialState: PreviewStae = {
  preview: undefined,
  pagination: undefined,
  previewList: undefined,
  isLoading: false,
};

export const previewSlice = createSlice({
  name: "preview",
  initialState,
  reducers: {
    storePreviews: (
      state,
      action: PayloadAction<Array<PreviewsResponse>>
    ) => {
      state.previewList = action.payload;
    },
    editPreview: (
      state,
      action: PayloadAction<PreviewsResponse>
    ) => {
      state.previewList =
        state.previewList &&
        state.previewList?.map((preview: PreviewsResponse) =>
          preview.id === action.payload.id
            ? { ...preview, ...action.payload }
            : preview
        );
    },
    storePreviewDetails: (
      state,
      action: PayloadAction<PreviewsResponse>
    ) => {
      state.preview = action.payload;
    },
    storePaginationDetails: (
      state,
      action: PayloadAction<PreviewsList>
    ) => {
      state.pagination = action.payload;
    },
    clearPreviewDetils: (state) => {
      state.preview = undefined;
    },
    saveLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  storePaginationDetails,
  storePreviews,
  editPreview,
  storePreviewDetails,
  clearPreviewDetils,
  saveLoading,
} = previewSlice.actions;

export default previewSlice.reducer;
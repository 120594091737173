import { LogoutOutlined } from "@mui/icons-material";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import {
	Avatar,
	Box,
	Button,
	Popover,
	// styled,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
// import { NavLink } from "react-router-dom";
import { signout } from "../../auth";
import { useAuthContext } from "../../contexts/Authcontext";
import { theme } from "../../theme";
import { BroadcastChannel } from "broadcast-channel";

const logoutChannel = new BroadcastChannel("logout");

const TopNavigation = () => {
	const { authUser, clearAll } = useAuthContext();
	const [name, setName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const open = Boolean(anchorEl);

	useEffect(() => {
		setName(`${authUser.given_name} ${authUser.family_name}`);
		setEmail(`${authUser.email}`);
	}, [authUser]);

	useEffect(() => {
		logoutChannel.onmessage = async () => {
			console.log("trigger logout");
			await signout();
			clearAll();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleNameClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = async () => {
		try {
			await signout();
			clearAll();
		} catch (error) {}
	};

	// const LinkWrapper = styled(NavLink)(({ theme }) => ({
	// 	padding: "16px",
	// 	textDecoration: "none",
	// 	color: "#000",
	// 	display: "flex",
	// 	alignItems: "center",
	// 	borderBottom: "1px solid #e6e6e6",
	// 	cursor: "pointer",
	// 	transition: "0.2s all ease-in-out",
	// 	"&:hover": {
	// 		background: theme.palette.background.paper,
	// 	},
	// }));
	return (
		<Box
			p={2}
			// sx={{ borderBottom: "1px solid #e6e6e6" }}
			display="flex"
			alignItems="center"
			justifyContent="flex-end"
			// boxSizing="border-box"
		>
			{/* <Box
				component="button"
				sx={{
					cursor: "pointer",
					border: "none",
					background: "none",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					boxSizing: "border-box",
				}}
				onClick={handleNameClick}
			>
				<Box> */}
			{/* <Typography
            variant="subtitle1"
            whiteSpace={"nowrap"}
            mr={1}
            fontWeight={theme.typography.fontWeightRegular}
            color={theme.palette.common.black}
          >
            {name}
          </Typography> */}
			<Button
				sx={{
					textTransform: "capitalize",
					fontWeight: "600",
					// color: "#141518",
				}}
				startIcon={
					<Avatar
						alt={name}
						src={name?.substring(0, 1)}
						sx={{
							width: 32,
							height: 32,
							marginLeft: "10px",
							background: theme.palette.common.black,
						}}
					/>
				}
				endIcon={<ArrowDropDownOutlinedIcon />}
				onClick={handleNameClick}
			>
				{name}
			</Button>
			{/* </Box>
			</Box> */}
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<Box
					minWidth="150px"
					display="flex"
					flexDirection="column"
					sx={{
						background: "#fff",
						boxShadow: "none !important",
						boxSizing: "border-box",
					}}
				>
					<Typography
						variant="caption"
						color={theme.palette.text.secondary}
						p={1}
						sx={{
							borderBottom: "1px solid #e6e6e6",
							boxSizing: "border-box",
						}}
					>
						{email}
					</Typography>
					<Box sx={{ boxSizing: "border-box" }}>
						{/* <LinkWrapper to="/settings">
              <Settings />
              <Typography mx={1} borderRadius={1} variant="subtitle1">
                Settings
              </Typography>
            </LinkWrapper> */}
						<Box
							display="flex"
							alignItems="center"
							p={2}
							sx={{
								boxSizing: "border-box",
								cursor: "pointer",
								transition: "0.2s all ease-in-out",
								"&:hover": {
									background: theme.palette.background.paper,
								},
							}}
							onClick={handleLogout}
						>
							<LogoutOutlined />
							<Typography
								mx={1}
								borderRadius={1}
								variant="subtitle1"
							>
								Logout
							</Typography>
						</Box>
					</Box>
				</Box>
			</Popover>
		</Box>
	);
};

export default TopNavigation;

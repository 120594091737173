import { Box, Grow, LinearProgress, styled } from "@mui/material";
import { FC, ReactNode } from "react";
import { LoadingAnimation } from "../../animations/LoadingAnimation";
import ContentWrapper from "../common/ContentWrapper";

interface Props {
	buttonGroups: ReactNode;
	list: any;
	emptyView: ReactNode;
	loading: boolean;
	dataGrid: ReactNode;
	search?: ReactNode;
	buttons?: boolean;
}
export const TableTemplate: FC<Props> = ({
	buttonGroups,
	list,
	emptyView,
	loading,
	dataGrid,
	search,
	buttons = false,
}) => {
	return (
		<ContentWrapper>
			<TableContainer>
				{buttonGroups}
				<TableView
					sx={{
						minHeight: list && list.length ? "auto" : "40vh",
						borderTopLeftRadius: buttons ? 0 : "20px",
					}}
				>
					{loading && list && list.length ? (
						<LinearProgress sx={{ zIndex: 10 }} />
					) : (
						<Box
							sx={{
								width: "100%",
								height: "4px",
								background: "none",
							}}
						/>
					)}

					<Box p={2} pb={0}>
						{search}
					</Box>

					{loading && (!list || (list && !list.length)) ? (
						<LoadingAnimation text="Loading..." />
					) : null}

					{list && list.length ? (
						<Box>
							<Grow in={true} timeout={500}>
								<Box>{dataGrid}</Box>
							</Grow>
						</Box>
					) : !loading ? (
						<Grow in={true} timeout={500}>
							<Box>{emptyView}</Box>
						</Grow>
					) : null}

					<Box sx={{ marginTop: "auto" }}>
						{loading && list && list.length > 10 ? (
							<LinearProgress sx={{ zIndex: 10 }} />
						) : (
							<Box
								sx={{
									width: "100%",
									height: "4px",
									background: "none",
								}}
							/>
						)}
					</Box>
				</TableView>
			</TableContainer>
		</ContentWrapper>
	);
};

export const TableContainer = styled(Box)(() => ({
	display: "flex",
	flexDirection: "column",
	background: "#F8F8F8",
	width: "100%",
	maxWidth: "1400px",
	margin: "0 auto",
	height: "100%",
	transition: "0.3s all ease-in-out",
}));

export const TableView = styled(Box)(({ theme }) => ({
	position: "relative",
	width: "100%",
	border: "1px solid #E8E9EB",
	borderTopLeftRadius: "0",
	borderTopRightRadius: "20px",
	borderBottomLeftRadius: "20px",
	borderBottomRightRadius: "20px",
	marginTop: "0",
	backgroundColor: "#fff",
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "stretch",
	transition: "0.3s all ease-in-out",
	overflow: "hidden",
	minHeight: "60vh",
	[theme.breakpoints.down("sm")]: {
		borderRadius: "14px",
	},
}));

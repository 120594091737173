import { Box } from "@mui/material";
import { FC } from "react";

interface IHeaderWrapper {
	children: React.ReactNode;
}

const wrapperStyle = {
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	background: "#F8F8F8",
	padding: "16px",
};
const HeaderWrapper: FC<IHeaderWrapper> = ({ children }) => {
	return <Box sx={{ ...wrapperStyle }}>{children}</Box>;
};

export default HeaderWrapper;
